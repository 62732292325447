/* Global Bootstrap Overrides and Custom Styles */

/* Navbar Styles */
.navbar-brand img {
  height: 180px; 
  width: auto;
}

.navbar {
  padding-top: 10px;
  padding-bottom: 10px;
}

.navbar-nav .nav-item .nav-link {
  color: #333;
}

.navbar-nav .nav-item .nav-link:hover {
  color: #007bff;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%288, 8, 8, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/* Additional global styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Custom styles for the projects page */
.project-form {
  margin-bottom: 2rem;
}

.project-card {
  margin-bottom: 1rem;
}

.project-item {
  text-align: center;
  cursor: pointer;
}

.project-image-wrapper {
  width: 100%;
  padding-top: 75%; /* Aspect ratio 4:3 */
  position: relative;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.project-title {
  margin-top: 10px;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
}

.project-item a {
  color: #333;
  text-decoration: none;
}

.project-item a:hover {
  text-decoration: underline;
}

/* Custom styles for the project details page */
.project-details {
  text-align: center;
  margin-top: 20px;
}

.project-details h2 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.project-details p {
  font-size: 1.2em;
  margin-bottom: 5px;
}

.carousel img.project-detail-image {
  max-width: 80%;
  height: auto;
  display: block;
  margin: 0 auto;
  object-fit: contain;
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .navbar-nav .nav-item {
    text-align: center;
  }

  .navbar-nav .nav-item .nav-link {
    display: block;
    padding: 0.5rem 1rem;
  }

  .btn {
    display: block;
    width: 100%;
    margin-top: 1rem;
  }

  .project-details h2 {
    font-size: 2em;
  }

  .project-details p {
    font-size: 1em;
  }

  .footer .btn {
    margin: 0.2rem;
  }
  
  .footer .btn i {
    font-size: 1.5rem;
  }
  
  .footer .btn:focus,
  .footer .btn:active {
    outline: none;
    box-shadow: none;
    transform: none;
  }

  .contact-us-section {
    padding: 40px 0;
    background-color: #f9f9f9;
  }
  
  .contact-location-btn {
    font-weight: bold;
    margin-bottom: 10px;
    background-color: #f0f0f0;
    border: none;
  }
  
  .contact-location-btn:hover {
    background-color: #e0e0e0;
  }
  
  .contact-icon {
    font-size: 1.5rem;
    color: #555;
    transition: color 0.3s ease;
  }
  
  .contact-icon:hover {
    color: #007bff;
  }

  .social-media-icons {
    flex-wrap: wrap !important;
    justify-content: center;
  }
  
  @media (max-width: 767px) {
    .social-media-icons {
      justify-content: center;
    }
  
    .social-media-icons .btn {
      margin: 5px;
    }
  
    .social-media-icons a {
      width: 100%;
      text-align: center;
      display: block;
      margin-top: 10px;
    }
  }


.about-us-section img.office-image {
  max-width: 100%;
  height: auto;
  width: 70%; 
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.card {
  border: none;
}

.card-img-top.team-member-image {
  height: 250px;
  object-fit: cover;
}

.card-title {
  font-size: 1.2em;
  font-weight: bold;
}

}


